import { assert } from '@orus.eu/error'

/**
 * A naïve representation of an address that works well for our business in France, and
 * hopefully in the first countries we expand to.
 */
export type Address = {
  /**
   * The name of the place, such as the name of the restaurant, or the name of a person
   */
  name: string
  /**
   * The number in the street and the name of the street, for example "32 Avenue Bosquet",
   * or "Place de la République"
   */
  street: string
  /**
   * The post code of the address, which was improperly named zipCode, but it would be too
   * costly to fix this confusion now.
   * Depending on the context, it might have to comply with the Wakam referential.
   */
  zipCode: string
  /**
   * The name of the city.
   * Depending on the context, it might have to comply with the Wakam referential and to be
   * consistent with the "zipCode" field
   */
  city: string
  /**
   * The name of the country
   */
  country: string
}

/**
 * A NullableAddress is useful for incomplete addresses. Missing fields are null
 */
export type NullableAddress = {
  [P in keyof Address]: Address[P] | null
}

/**
 * Checks if two addresses are the same
 */
export function addressesEqual(address1: NullableAddress | null, address2: NullableAddress | null): boolean {
  if (address1 === null || address2 === null) {
    return address1 === address2
  }

  return (
    address1.city === address2.city &&
    address1.country === address2.country &&
    address1.name === address2.name &&
    address1.street === address2.street &&
    address1.zipCode === address2.zipCode
  )
}

/**
 * Converts a nullableAddress to an Address if possible
 * @param nullableAddress
 * @return a valid Address object if the address is valid. Returns null otherwise
 */
export function validateNullableAddress(nullableAddress: NullableAddress): Address | null {
  const { name, city, country, zipCode, street } = nullableAddress
  if (
    name !== null &&
    name !== '' &&
    city !== null &&
    city !== '' &&
    country !== null &&
    country !== '' &&
    zipCode !== null &&
    zipCode !== '' &&
    street !== null &&
    street !== ''
  ) {
    return { name, city, country, zipCode, street }
  }
  return null
}

/**
 * Returns true if the nullable address can be con converted to a Address
 * @param nullableAddress
 */
export function isAddress(nullableAddress: NullableAddress): nullableAddress is Address {
  return validateNullableAddress(nullableAddress) !== null
}

/**
 * Format an address
 * @param address: Address to format
 * @param returnToLine. if true, returns the name in a separate line
 */
export function addressToString(address: Partial<NullableAddress> | null, returnToLine = true): string {
  if (!address) {
    return ''
  }
  let addressString = ''
  if (address) {
    let { name, street, city, zipCode } = address
    if (name) {
      name = name.trim()
    }
    if (street) {
      street = street.trim()
    }
    if (city) {
      city = city.trim()
    }
    if (zipCode) {
      zipCode = zipCode.trim()
    }

    if (name) {
      addressString += name
    }

    if (street) {
      if (addressString) {
        addressString += returnToLine ? '\n' : ', '
      }
      addressString += street
    }

    if (zipCode) {
      if (addressString) {
        addressString += ', '
      }
      addressString += zipCode

      if (city) {
        addressString += ' ' + city
      }
    } else {
      if (city) {
        if (addressString) {
          addressString += ', '
        }
        addressString += city
      }
    }
  }
  return addressString
}

/**
 * Represent information about the street part of an address  (example "2 rue de la Paix")
 * in a structured way
 */
export type FrenchAddressStreetInfo = {
  /**
   * The number in the street, such as "1", "2bis", "3C", etc...
   */
  number: string | null
  /**
   * The name of the street, such as "Rue de la Paix"
   */
  name: string
}

export function parseFrenchStreetInfo(input: string): FrenchAddressStreetInfo | null {
  const groups = /^(\d+\s*(?:[a-z]|[A-Z]|bis|ter|BIS|TER|Bis|Ter)?[ ,])?(\D.+)$/.exec(input)

  if (!groups || groups.length !== 3) {
    return null
  }

  const group2 = groups[2]
  assert(group2 != null, 'group2 should be defined')

  if (!groups[1]) {
    return {
      number: null,
      name: group2.trim(),
    }
  }

  return {
    number: groups[1].replace(',', '').trim(),
    name: group2.trim(),
  }
}

/**
 * Create an new empty address with the France field initialized
 */
export function createEmptyFrenchAddress(): NullableAddress {
  return {
    name: null,
    street: null,
    country: 'France',
    city: null,
    zipCode: null,
  }
}

/**
 * Formats a French address to display on one line.
 *
 * @param address the address to format
 */
export function formatFrenchAddress1Line(address: Partial<NullableAddress> | null): string {
  return addressToString(address, false)
}

export const frenchPostCodeRegex = /^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/
